import React from "react";
const Error = ({ error }) => {
  return (
    <div>
      <div>{error?.message}</div>
    </div>
  );
};

export default Error;
