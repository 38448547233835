import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import React from "react";
import { useMutation } from "@apollo/client";
import { CREATEBETTING } from "../graphql/mutations";

import dayjs from "dayjs";
import { Mood, SentimentVeryDissatisfied } from "@mui/icons-material";
const BettingRow = ({ match }) => {
  const [createBetting, { loading }] = useMutation(CREATEBETTING);
  const handleRadioClick = (value) => {
    createBetting({
      variables: {
        matchId: parseInt(match.id, 10),
        value: parseInt(value, 10),
      },
    })
      .then(() => {
        console.log("done");
      })
      .catch((err) => {
        alert.error(`Ups, das hat nicht funktioniert: ${err.message}`);
      });
  };
  const _radios = [
    { value: 1, name: "HEIM" },
    { value: 0, name: "REMIS" },
    {
      value: 2,
      name: "GAST",
    },
  ];

  const _scored = match?.bettings[0]?.value === match.toto;

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <div style={{ height: "10px", position: "relative" }}>
          <div style={{ position: "absolute", right: "10px" }}>
            {match.toto !== null && (
              <div>
                {_scored === true ? (
                  <Mood color="primary"></Mood>
                ) : (
                  <SentimentVeryDissatisfied color="error" />
                )}
              </div>
            )}
          </div>
        </div>
        <Typography align="center">
          {dayjs(new Date(parseInt(match.matchDateTime, 10))).format(
            "D.MM.YYYY"
          )}
        </Typography>
        <Typography align="center">
          {dayjs(new Date(parseInt(match.matchDateTime, 10))).format(
            "HH:mm [Uhr]"
          )}
        </Typography>

        <Grid container>
          <Grid item xs={5}>
            <img
              alt={match.homeTeam.name}
              style={{
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
                width: "50px",
                objectFit: "contain",
                height: "100px",
              }}
              src={match.homeTeam.IconUrl}
            ></img>
            <Typography align="center">{match.homeTeam.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ marginTop: "40px" }}>
              {match.goalsteam1 !== null && (
                <Typography align="center">{`${match.goalsteam1} : ${match.goalsteam2}`}</Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={5}>
            <img
              alt={match.awayTeam.name}
              style={{
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
                width: "50px",
                objectFit: "contain",
                height: "100px",
              }}
              src={match.awayTeam.IconUrl}
            ></img>
            <Typography align="center">{match.awayTeam.name}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container>
          {_radios.map((_radio) => (
            <Grid key={`${_radio.name}`} item xs={4} sx={{ padding: "5px" }}>
              <Button
                variant={
                  match?.bettings[0]?.value === parseInt(_radio.value, 10)
                    ? "contained"
                    : "outlined"
                }
                fullWidth
                disabled={
                  new Date() > new Date(parseInt(match.matchDateTime, 10)) ||
                  loading
                }
                onClick={() => handleRadioClick(_radio.value)}
              >
                {_radio.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default BettingRow;
