import React, { useEffect } from "react";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import AuthService from "./authService";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_APIURI}/graphql`,
});
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: `Bearer ${AuthService.getToken()}`, // however you get your token
      ...headers,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  connectToDevTools: true,
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({}),
});

const ApiWrapper = React.memo(({ children }) => {
  if (client) {
    return <ApolloProvider client={client}> {children} </ApolloProvider>;
  } else {
    return <div>NICHT AUTHENTIFIZIERT </div>;
  }
});

const ApolloAuthWrapper = ({ children }) => {
  useEffect(() => {
    const _token = AuthService.getToken() || "";
    if (!_token) {
      AuthService.doLogin();
    }
    const intervalId = setInterval(async () => {
      await AuthService.updateToken(() => null);
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return <ApiWrapper>{children} </ApiWrapper>;
};

export default ApolloAuthWrapper;
