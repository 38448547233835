import { useQuery } from "@apollo/client";

import React from "react";
import GameRanking from "../components/GameRanking";
import Error from "../components/helper/Error";
import Loading from "../components/helper/Loading";
import { CURRENTMATCHDAY } from "../graphql/queries";

const GameRankingPage = ({ year }) => {
  const { loading, error, data } = useQuery(CURRENTMATCHDAY, { year });
  if (error) return <Error error={error}></Error>;
  if (loading) return <Loading></Loading>;
  const { currentMatchday } = data;
  return <GameRanking year={currentMatchday.year}></GameRanking>;
};

export default GameRankingPage;
