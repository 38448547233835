import { Tab, Tabs } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
const TopTabs = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Tabs value={pathname} variant="fullWidth">
      <Tab
        value="/"
        label={
          <Link
            style={{ color: "white", opacity: 1, textDecoration: "none" }}
            to="/"
          >
            Tippen
          </Link>
        }
      ></Tab>
      <Tab
        value="/spieltagergebnis"
        label={
          <Link
            style={{ color: "white", opacity: 1, textDecoration: "none" }}
            to="/spieltagergebnis"
          >
            Spieltag
          </Link>
        }
      ></Tab>
      <Tab
        value="/tabelle"
        label={
          <Link
            style={{ color: "white", opacity: 1, textDecoration: "none" }}
            to="/tabelle"
          >
            Tabelle
          </Link>
        }
      ></Tab>
    </Tabs>
  );
};

export default TopTabs;
