import { Avatar } from "@mui/material";
import React from "react";
import { useQuery } from "@apollo/client";
import { ME } from "../graphql/queries";
const MyAvatar = () => {
  const { error, loading, data } = useQuery(ME);
  if (loading) return <div></div>;
  if (error) return <div></div>;
  const _name = `${data.me?.firstName} ${data.me?.lastName}`;
  if (!data.me) return <></>;
  return (
    <Avatar
      alt={`${_name}`}
      src={`https://minio.linos-it.de/api/v1/buckets/bulitoto/objects/download?preview=true&prefix=${data.me.id}.jpg`}
    >
      {data.me?.firstName.substring(0, 1)} {data.me?.lastName.substring(0, 1)}
    </Avatar>
  );
};

export default MyAvatar;
