import React from "react";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AppBar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopTabs from "./TopTabs";
import UserService from "../../lib/auth/authService";
import MyAvatar from "../../components/MyAvatar";
import { Link } from "react-router-dom/dist";
export default function TopNavigation() {
  const navigate = useNavigate();
  return (
    <AppBar>
      <Toolbar>
        <Typography
          component="a"
          onClick={() => {
            navigate("/");
          }}
          variant="h6"
          sx={{ flexGrow: 1, ":hover": { cursor: "pointer" } }}
        >
          BULI-TOTO
        </Typography>

        {UserService.isLoggedIn() ? (
          <>
            <Box sx={{ flexGrow: 0 }}>
              <Link to="/photoupload">
                <MyAvatar></MyAvatar>
              </Link>
            </Box>
          </>
        ) : (
          <div></div>
        )}
      </Toolbar>

      {UserService.isLoggedIn() === true && <TopTabs></TopTabs>}
    </AppBar>
  );
}
