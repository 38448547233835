const { gql } = require("@apollo/client");

export const CREATEBETTING = gql`
  mutation CreateBetting($matchId: Int!, $value: Int!) {
    createBetting(input: { matchId: $matchId, value: $value }) {
      id
      bettings {
        id
        value
      }
    }
  }
`;
