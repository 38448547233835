import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import { createRoot } from "react-dom/client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider, createTheme } from "@mui/material";
import { green } from "@mui/material/colors";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ApolloAuthWrapper from "./lib/auth/ApolloAuthWrapper";
import AuthService from "./lib/auth/authService";
const theme = createTheme({
  palette: {
    primary: green,
  },
});

const root = createRoot(document.getElementById("root"));

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ApolloAuthWrapper>
          <App />
        </ApolloAuthWrapper>
      </ThemeProvider>
    </React.StrictMode>
  );
};

AuthService.initKeycloak(renderApp);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
