import React from "react";
import { useQuery } from "@apollo/client";
import { CURRENTMATCHDAY } from "../graphql/queries";
import BettingsTable from "../components/BettingsTable";
import Loading from "../components/helper/Loading";

const BettingsPage = () => {
  const { loading, error, data } = useQuery(CURRENTMATCHDAY);

  if (error) return <div>Error</div>;
  if (loading) return <Loading />;
  const { currentMatchday } = data;
  return <BettingsTable currentMatchday={currentMatchday}></BettingsTable>;
};

export default BettingsPage;
