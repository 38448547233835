import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BettingsPage from "./pages/BettingsPage";
import MatchBettingResultPage from "./pages/MatchBettingResultPage";
import { AppBar, Box, Container } from "@mui/material";
import ProtectedRoute from "./pages/layout/ProtectedRoute";
import TopNavigation from "./pages/layout/TopNavigation";

import GameRankingPage from "./pages/GameRankingPage";
import PhotoUpload from "./pages/PhotoUpload";

function App() {
  return (
    <Container>
      <Router>
        <AppBar>
          <TopNavigation></TopNavigation>
        </AppBar>

        <Box sx={{ marginTop: "120px" }}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <BettingsPage></BettingsPage>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              exact
              path="/spieltagergebnis"
              element={
                <ProtectedRoute>
                  <MatchBettingResultPage></MatchBettingResultPage>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              exact
              path="/tabelle"
              element={
                <ProtectedRoute>
                  <GameRankingPage></GameRankingPage>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              exact
              path="/photoupload"
              element={
                <ProtectedRoute>
                  <PhotoUpload></PhotoUpload>
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </Box>
      </Router>
    </Container>
  );
}

export default App;
