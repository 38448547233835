import { useQuery } from "@apollo/client";
import {
  Avatar,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Error from "../components/helper/Error";
import Loading from "../components/helper/Loading";
import { GAMERANKING } from "../graphql/queries";
import { useState } from "react";
import { Refresh } from "@mui/icons-material";

const GameRanking = ({ year }) => {
  const [refetching, setRefetching] = useState(false);
  const { loading, error, data, refetch } = useQuery(GAMERANKING, {
    variables: { year },
  });
  if (error) return <Error error={error}></Error>;
  if (loading) return <Loading></Loading>;
  const { gameRanking } = data;
  return (
    <Container>
      <div>
        <div
          style={{
            width: "30px",
            height: "40px",
            marginLeft: "auto",
            marginRight: "0",
          }}
        >
          <IconButton
            color="primary"
            variant="contained"
            disabled={refetching}
            onClick={async () => {
              setRefetching(true);
              await refetch();
              setRefetching(false);
            }}
          >
            <Refresh></Refresh>
          </IconButton>
        </div>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Team</TableCell>
            {/* <TableCell>Spiele</TableCell> */}
            <TableCell>Tore</TableCell>
            {/* <TableCell>Gegentore</TableCell> */}
            {/* <TableCell>Diff.</TableCell> */}
            <TableCell>Pkt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {gameRanking.map((_rank, i) => (
            <TableRow key={_rank.player}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                <Avatar
                  alt={_rank.player}
                  src={`${process.env.REACT_APP_APIURI}/avatar/${_rank.playerid}`}
                ></Avatar>
              </TableCell>
              <TableCell>{_rank.player}</TableCell>
              {/* <TableCell>{_rank.team}</TableCell> */}
              {/* <TableCell>{_rank.spiele}</TableCell> */}
              <TableCell>
                {_rank.tore}:{_rank.gegentore} ({_rank.tdiff})
              </TableCell>
              {/* <TableCell>{_rank.gegentore}</TableCell> */}
              {/* <TableCell>{_rank.tdiff}</TableCell> */}
              <TableCell>{_rank.punkte}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default GameRanking;
