import React, { useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import UserService from "../lib/auth/authService";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

import { useQuery } from "@apollo/client";
import { ME } from "../graphql/queries";
import Loading from "../components/helper/Loading";
import Error from "../components/helper/Error";
import { Avatar } from "@mui/material";

const PhotoUpload = () => {
  const [imageKey, setImageKey] = useState(0);
  const { error, loading, data } = useQuery(ME);
  const [uploading, setUploading] = useState(false);
  const handleUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append("avatar", e.target.files[0]);

        await axios.post(
          `${process.env.REACT_APP_APIURI}/uploadAvatar`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );

        // Optional: Add logic to handle successful upload
        console.log("Image uploaded successfully");
      } catch (error) {
        // Handle upload error
        console.error("Error uploading image:", error);
      }
      setUploading(false);
      setImageKey((prevKey) => prevKey + 1);
    }
  };
  if (loading) return <Loading></Loading>;
  if (error) return <Error></Error>;

  return (
    <div style={{ height: "50vh" }}>
      <div
        style={{
          marginTop: "200px",
          width: "200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Avatar
          key={imageKey}
          style={{ width: "200px", height: "200px" }}
          alt={`${data.me?.firstName}`}
          src={`${process.env.REACT_APP_APIURI}/avatar/${data.me.id}?${imageKey}`}
        >
          {data.me?.firstName.substring(0, 1)}{" "}
          {data.me?.lastName.substring(0, 1)}
        </Avatar>
      </div>
      <div
        style={{
          width: "200px",
          marginTop: "100px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <input
          type="file"
          accept="image/jpeg, image/jpg"
          onChange={handleUpload}
          style={{ display: "none" }}
          id="roomImageUploadInput"
        />
        <label htmlFor="roomImageUploadInput">
          <Button
            fullWidth
            disabled={uploading}
            component="span"
            variant="contained"
            color="primary"
          >
            {uploading ? (
              <CircularProgress></CircularProgress>
            ) : (
              "Foto auswählen"
            )}
          </Button>
        </label>
      </div>
      <div
        style={{
          marginTop: "50px",
          width: "200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            UserService.doLogout();
          }}
        >
          Abmelden
        </Button>
      </div>
      <div></div>
    </div>
  );
};

export default PhotoUpload;
