import React from "react";
import HomePage from "../HomePage";
import UserService from "../../lib/auth/authService";

const ProtectedRoute = ({ children }) => {
  return UserService.isLoggedIn() === true ? (
    <>{children}</>
  ) : (
    <HomePage to="/"></HomePage>
  );
};

export default ProtectedRoute;
