import { Button, Container } from "@mui/material";
import React from "react";
import UserService from "../lib/auth/authService";

const HomePage = () => {
  // if (UserService.isLoggedIn() === true) return <Navigate to="/"></Navigate>;
  return (
    <Container>
      <div
        style={{
          width: "200px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "40vh",
        }}
      >
        <Button
          onClick={() => {
            UserService.doLogin();
          }}
          fullWidth
          variant="contained"
          color="primary"
        >
          ANMELDEN
        </Button>
      </div>
    </Container>
  );
};

export default HomePage;
