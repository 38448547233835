import React, { useState } from "react";
import { Button, Typography, Container, IconButton } from "@mui/material";

import BettingRow from "../components/BettingRow";
import Loading from "./helper/Loading";

import { MATCHES } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import { Refresh } from "@mui/icons-material";
import UserMatchDayResult from "./UserMatchDayResult";
const BettingsTable = ({ currentMatchday }) => {
  const [refetching, setRefetching] = useState(false);
  const [matchday, setMatchday] = useState(currentMatchday.matchday);
  const { loading, error, data, refetch } = useQuery(MATCHES, {
    variables: {
      year: currentMatchday.year,
      matchday,
    },
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading></Loading>;
  const { matches, userMatchdayResults } = data;
  const _userMatchdayResult = userMatchdayResults[0];

  return (
    <Container>
      <div style={{ position: "relative", height: "35px", paddingTop: "10px" }}>
        <Typography
          align="center"
          variant="h5"
        >{`${matchday}. Spieltag`}</Typography>
        <div style={{ position: "absolute", right: 0, top: "-5px" }}>
          <IconButton
            color="primary"
            variant="contained"
            disabled={refetching}
            onClick={async () => {
              setRefetching(true);
              await refetch();
              setRefetching(false);
            }}
          >
            <Refresh></Refresh>
          </IconButton>
        </div>
      </div>
      <div>
        <UserMatchDayResult
          userMatchdayResult={_userMatchdayResult}
        ></UserMatchDayResult>
      </div>
      <div className="mt20">
        <Button
          variant="contained"
          disabled={matchday <= 1}
          fullWidth
          onClick={() => {
            setMatchday(matchday - 1);
          }}
        >
          Vorheriger Spieltag
        </Button>
        <div className="mb20">
          <Button
            sx={{ mt: 1, mb: 15 }}
            variant="contained"
            color="primary"
            disabled={matchday >= 34}
            fullWidth
            onClick={() => {
              setMatchday(matchday + 1);
            }}
          >
            Nächster Spieltag
          </Button>
        </div>
      </div>

      {matches
        .filter((match) => match.matchday === matchday)
        .map((match) => {
          return <BettingRow key={match.id} match={match}></BettingRow>;
        })}
      <div className="mt20 mb20">
        <Button
          sx={{ mt: 5, mb: 15 }}
          variant="contained"
          color="primary"
          disabled={matchday >= 34}
          fullWidth
          onClick={() => {
            setMatchday(matchday + 1);
          }}
        >
          Nächster Spieltag
        </Button>
      </div>
    </Container>
  );
};

export default BettingsTable;
