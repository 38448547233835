import React from "react";
import { useQuery } from "@apollo/client";
import { CURRENTMATCHDAY } from "../graphql/queries";
import MatchBettingResultTable from "../components/MatchBettingResultTable";

import Loading from "../components/helper/Loading";

const MatchBettingResultPage = () => {
  const { loading, error, data } = useQuery(CURRENTMATCHDAY);

  if (error) return <div>Error</div>;
  if (loading) return <Loading></Loading>;
  const { currentMatchday } = data;
  return (
    <MatchBettingResultTable
      currentMatchday={currentMatchday}
    ></MatchBettingResultTable>
  );
};

export default MatchBettingResultPage;
