import { Avatar, Grid, Box, Typography } from "@mui/material";
import React from "react";

const UserMatchDayResult = ({ userMatchdayResult }) => {
  const {
    id,
    year,
    matchday,
    user1id,
    user1firstname,
    user1lastname,
    user2id,
    user2firstname,
    user2lastname,
    goalsuser1,
    goalsuser2,
  } = userMatchdayResult;
  const _height = "40px";
  return (
    <Box>
      <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            display: "inline-block",
            width: "30%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <div style={{ paddingRight: "5px", textOverflow: "ellipsis" }}>
            {`${user1firstname.substring(0, 2)}. ${user1lastname}`}
          </div>
        </div>
        <div
          style={{
            width: "40%",
            display: "inline-block",
          }}
        >
          <div style={{ position: "relative", height: _height }}>
            <Avatar
              sx={{
                display: "inline-block",
                position: "absolute",
                top: "14px",
                height: 35,
                width: 35,
              }}
              alt={`${user1firstname} ${user1lastname}`}
              src={`${process.env.REACT_APP_APIURI}/avatar/${user1id}`}
            ></Avatar>
            <div
              style={{
                position: "absolute",
                left: "calc(50% - 20px)",
                top: "15px",
                display: "inline-block",
                width: "50px",
                height: _height,
                padding: "0 10px 5px 10px",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                }}
              >
                <span>{goalsuser1}</span>
                <span>:</span>
                <span>{goalsuser2}</span>
              </span>
            </div>

            <Avatar
              sx={{
                display: "inline-block",
                position: "absolute",
                right: "-12px",
                top: "14px",
                height: 35,
                width: 35,
              }}
              alt={`${user2firstname} ${user2lastname}`}
              src={`${process.env.REACT_APP_APIURI}/avatar/${user2id}`}
            ></Avatar>
          </div>
        </div>
        <div
          style={{
            width: "30%",
            display: "inline-block",
            position: "relative",
          }}
        >
          <div
            style={{
              marginLeft: "20px",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ textOverflow: "ellipsis", textAlign: "right" }}>
              {`${user2firstname.substring(0, 2)}. ${user2lastname}`}
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </Box>
  );
};

export default UserMatchDayResult;
