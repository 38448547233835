import { gql } from "@apollo/client";

export const TEAMS = gql`
  query Teams {
    teams {
      id
      name
      IconUrl
      matchesHome {
        id
        matchday
      }
    }
  }
`;
export const MATCHES = gql`
  query Matches($year: Int!, $matchday: Int!) {
    matches(year: $year, matchday: $matchday) {
      id
      matchday
      matchDateTime
      toto
      goalsteam1
      goalsteam2
      homeTeam {
        id
        name
        IconUrl
      }
      bettings {
        id
        value
      }
      awayTeam {
        id
        name
        IconUrl
      }
    }
    userMatchdayResults(year: $year, matchday: $matchday) {
      id
      year
      matchday
      user1id
      user1firstname
      user1lastname
      user2id
      user2firstname
      user2lastname
      goalsuser1
      goalsuser2
    }
  }
`;
export const MYMATCHBETTING = gql`
  query MyMatchBetting($matchId: Int!) {
    myMatchBetting(matchId: $matchId) {
      id
      matchId
      value
    }
  }
`;

export const CURRENTMATCHDAY = gql`
  query currentMatchday {
    currentMatchday {
      matchday
      year
    }
  }
`;
export const ME = gql`
  query ME {
    me {
      id
      firstName
      lastName
    }
  }
`;

export const MATCHBETTINGRESULT = gql`
  query Matchbettingresult($matchday: Int!, $seasonId: Int!) {
    matchbettingresult(matchday: $matchday, seasonId: $seasonId) {
      id
      playerid1
      playerid2
      teamname1
      playername1
      hits1
      teamname2
      playername2
      hits2
    }
  }
`;
export const GAMERANKING = gql`
  query GameRanking($year: Int!) {
    gameRanking(year: $year) {
      id
      leagueId
      seasonId
      spiele
      teamid
      team
      playerid
      player
      punkte
      tdiff
      tore
      gegentore
    }
  }
`;
