import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Error from "./helper/Error";
import Loading from "./helper/Loading";
import { MATCHBETTINGRESULT } from "./../graphql/queries";
import {
  Avatar,
  Button,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import UserMatchDayResult from "./UserMatchDayResult";
const MatchBettingResultTable = ({ currentMatchday }) => {
  const [refetching, setRefetching] = useState(false);
  const [matchday, setMatchday] = useState(currentMatchday.matchday);
  const { error, loading, data, refetch } = useQuery(MATCHBETTINGRESULT, {
    variables: {
      matchday,
      seasonId: currentMatchday.year,
    },
  });
  if (error) return <Error></Error>;
  if (loading) return <Loading></Loading>;
  const { matchbettingresult } = data;

  return (
    <Container>
      <div className="tac" style={{ position: "relative", height: "45px" }}>
        <Typography
          variant="h5"
          paragraph
        >{`${matchday}. Spieltag`}</Typography>
        <div style={{ position: "absolute", right: 0, top: "-5px" }}>
          <IconButton
            color="primary"
            variant="contained"
            disabled={refetching}
            onClick={async () => {
              setRefetching(true);
              await refetch();
              setRefetching(false);
            }}
          >
            <Refresh></Refresh>
          </IconButton>
        </div>
      </div>

      <div>
        <Button
          variant="outlined"
          disabled={matchday === 1}
          onClick={() => {
            setMatchday(matchday - 1);
          }}
          fullWidth
        >
          VORHERIGER SPIELTAG
        </Button>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matchbettingresult.map((br) => (
            <TableRow key={br.id}>
              <TableCell>
                <div style={{ display: "inline-block", widht: "50px" }}>
                  <Avatar
                    alt={br.playername1}
                    src={`${process.env.REACT_APP_APIURI}/avatar/${br.playerid1}`}
                  ></Avatar>
                </div>
                <span className="playername">{br.playername1}</span>
                <span className="teamname">{br.teamname1}</span>
              </TableCell>

              <TableCell>-</TableCell>
              <TableCell>
                <div style={{ display: "inline-block", widht: "50px" }}>
                  <Avatar
                    alt={br.playername1}
                    src={`${process.env.REACT_APP_APIURI}/avatar/${br.playerid2}`}
                  ></Avatar>
                </div>
                <span className="playername">{br.playername2}</span>
                <span className="teamname">{br.teamname2}</span>
              </TableCell>
              <TableCell>
                {br.hits1}:{br.hits2}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <Button
          variant="outlined"
          disabled={matchday === 34}
          onClick={() => {
            setMatchday(matchday + 1);
          }}
          fullWidth
        >
          NÄCHSTER SPIELTAG
        </Button>
      </div>
    </Container>
  );
};

export default MatchBettingResultTable;
