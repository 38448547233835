import { CircularProgress, Container } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Container sx={{ minHeight: "90vh", margin: "auto" }}>
      <div
        style={{
          position: "fixed",
          marginTop: "35vh",
          width: "50px",
          marginLeft: "40vw",
        }}
      >
        <CircularProgress></CircularProgress>
      </div>
    </Container>
  );
};

export default Loading;
